<template>
  <div>
    <div class="pl-8 pr-8 ml-3 mr-3 mb-xl-vacancies">
      <v-row justify="center">
        <v-col class="pl-0 pr-0 text-left" cols="12">
          <h1 class="vacancies__title">Vacantes</h1>
        </v-col>
        <!-- <v-col class="mt-16 pt-4 pl-0 pr-0" cols="4">
          <v-btn dark block rounded color="#466BE3"> Crear vacante </v-btn>
        </v-col> -->
      </v-row>
      <v-row>
        <v-col cols="6">
          <p class="mt-3 mb-2 text-left vacancies__subtitle">
            {{ text.title }}
          </p>
          <v-card class="px-8 pb-5 pt-4" outlined style="border-radius: 15px">
            <v-form ref="form" class="mt-5">
              <v-row justify="end">
                <v-col class="pb-0 pt-0" cols="6">
                  <v-text-field
                    v-model="form.title"
                    label="Título de la vacante *"
                    required
                    color="#466BE3"
                    :rules="[(v) => !!v || 'Este campo es requerido']"
                  ></v-text-field>
                </v-col>
                <v-col class="pb-0 pt-1" cols="6">
                  <v-file-input
                    v-if="form.img == true"
                    v-model="form.file"
                    @change="handleFileUpload"
                    placeholder="Imagen"
                    label="Adjuntar imagen"
                    accept="image/*"
                  >
                    <template v-slot:selection="{ text }">
                      <v-chip small label color="primary">
                        {{ text }}
                      </v-chip>
                    </template>
                  </v-file-input>
                  <v-btn
                    class="mt-3"
                    v-if="form.img != true"
                    text
                    color="#466BE3"
                    @click="openImg = true"
                  >
                    <strong> Mostrar imagen </strong>
                  </v-btn>
                  <v-btn
                    class="mt-3"
                    v-if="form.img != true"
                    text
                    color="#466BE3"
                    @click="closeImg()"
                  >
                    <v-icon> mdi-close </v-icon>
                  </v-btn>
                </v-col>
                <v-col class="pb-0 pt-1" cols="6">
                  <v-select
                    :rules="[(v) => !!v || 'Este campo es requerido']"
                    v-model="form.pais"
                    :items="listCountry"
                    label="País de la vacante*"
                    item-text="pais"
                    item-value="pais"
                    @change="handleCountryChange"
                    
                  ></v-select>
                </v-col>
                <v-col class="pb-0 pt-1" cols="6">
                  <v-select
                    :rules="[(v) => !!v || 'Este campo es requerido']"
                    v-model="form.ciudad"
                    :items="listCities"
                    label="Ciudad de la vacante *"
                    item-text="headquarters_city"
                    item-value="headquarters_city"
                    :disabled="listCities.length === 0"
                  ></v-select>
                </v-col>
                <v-col class="pb-0 pt-1" cols="6">
                  <v-select
                    :rules="[(v) => !!v || 'Este campo es requerido']"
                    v-model="form.tipo_contrato"
                    :items="listContract"
                    label="Tipo de contrato *"
                  ></v-select>
                </v-col>
                <v-col class="pb-0 pt-0" cols="6">
                  <v-text-field
                    v-model="form.formacion"
                    label="Formación*"
                    required
                    color="#466BE3"
                    :rules="[(v) => !!v || 'Este campo es requerido']"
                  ></v-text-field>
                </v-col>
                <v-col class="pb-0 pt-0" cols="6">
                  <v-select
                    :rules="[(v) => !!v || 'Este campo es requerido']"
                    v-model="form.tipo_modalidad"
                    :items="listMode"
                    label="Modalidad*"
                  ></v-select>
                </v-col>
                <v-col class="pb-0 pt-1" cols="6">
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :rules="[(v) => !!v || 'Este campo es requerido']"
                        v-model="form.mes_ingreso"
                        label="Cierre de convocatoria*"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        required
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="form.mes_ingreso"
                      @input="menu2 = false"
                      :min="minDate"
                    ></v-date-picker>
                  </v-menu>
                  <!-- <v-text-field
                    v-model="form.mes_ingreso"
                    label="Mes de ingreso"
                    required
                    color="#466BE3"
                    :rules="[(v) => !!v || 'Este campo es requerido']"
                  ></v-text-field> -->
                </v-col>
                <v-col class="pb-0 pt-0" cols="6">
                  <v-text-field
                    :rules="[(v) => !!v || 'Este campo es requerido']"
                    v-model="form.numero_de_vacantes"
                    label="Número de vacante*"
                    type="number"
                    required
                    color="#466BE3"
                  ></v-text-field>
                </v-col>
                <v-col class="pb-0 pt-0" cols="6">
                  <v-select
                    :rules="[(v) => !!v || 'Este campo es requerido']"
                    :items="costCenters"
                    label="Centro de costos"
                    v-model="form.costCentersId"
                    item-text="cc"
                    item-value="cc"
                    @change="listSubCostCenter"
                    required
                  >
                    <template v-slot:prepend-inner>
                      <v-icon color="#afb0b0"> mdi-filter-outline </v-icon>
                    </template>
                  </v-select>
                </v-col>
                <v-col class="pb-0 pt-0" cols="6">
                  <v-select
                    :items="subCostCenters"
                    label="Sub Centro de Costos"
                    v-model="form.subCostCentersId"
                    item-text="cod_scc"
                    item-value="cod_scc"
                    @change="listCampaign"
                    :disabled="subCostCenters.length === 0"
                    required
                    :rules="[(v) => !!v || 'Este campo es requerido']"
                  >
                    <template v-slot:prepend-inner>
                      <v-icon color="#afb0b0"> mdi-filter-outline </v-icon>
                    </template>
                  </v-select>
                </v-col>
                <v-col class="pb-0 pt-0" cols="6">
                  <v-select
                    :items="campaign"
                    label="Campaña"
                    v-model="form.campaignId"
                    item-text="campaign"
                    item-value="campaign"
                    :disabled="campaign.length === 0"
                  >
                    <template v-slot:prepend-inner>
                      <v-icon color="#afb0b0"> mdi-filter-outline </v-icon>
                    </template>
                  </v-select>
                </v-col>
                <v-col class="pb-0 pt-0" cols="6">
                  <v-text-field
                    :rules="[(v) => !!v || 'Este campo es requerido']"
                    v-model="form.horario"
                    label="Horario*"
                    required
                    color="#466BE3"
                  ></v-text-field>
                </v-col>
                <v-col>
                </v-col>
                <v-col class="pb-0 pt-1" cols="12">
                  <vue-editor
                    ref="editor"
                    v-model="form.objetivo"
                    :editor-toolbar="customToolbar"
                  ></vue-editor>
                </v-col>
                <v-col class="pb-0 pt-1" cols="12">
                  <v-textarea
                    :rules="[
                      (v) => !!v || 'Este campo es requerido',
                      (v) =>
                        v.length <= 200 || 'Supera el límite de caracteres ',
                    ]"
                    v-model="form.experiencia"
                    name="input-7-1"
                    label="Experiencia*"
                    counter=""
                    value=""
                    rows="4"
                  ></v-textarea>
                </v-col>
                <v-col class="pb-0 pt-1" cols="6">
                  <v-text-field
                    :rules="[(v) => !!v || 'Este campo es requerido']"
                    v-model="form.rango_salarial"
                    label="Rango salarial*"
                    required
                    color="#466BE3"
                  ></v-text-field>
                </v-col>
                <v-col class="pb-0 pt-1" cols="6">
                  <v-select
                    :rules="[(v) => !!v || 'Este campo es requerido']"
                    v-model="form.status"
                    item-text="name"
                    item-value="id"
                    :items="listStatus"
                    label="Estados"
                  ></v-select>
                </v-col>
                <v-col class="pb-0 pt-1" cols="6" style="border-radius: 15px">
                  <v-btn
                    @click="saveVacancies"
                    :loading="loading.add"
                    :disabled="loading.add"
                    class="mb-5 mt-2"
                    dark
                    block
                    rounded
                    color="#466BE3"
                  >
                    {{ text.btn }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-row>
            <v-col class="pb-1" cols="6">
              <p class="mt-3 mb-2 text-left vacancies__subtitle">
                Lista de vacantes
              </p>
            </v-col>
            <v-col class="pb-1" cols="6">
              <v-select
                v-model="filter"
                :items="listFilters"
                label="Filtros"
                item-text="name"
                item-value="id"
              ></v-select>
            </v-col>
          </v-row>
          <v-data-table
            style="
              border-radius: 15px;
              height: 93%;
              border: solid 1.2px #e0e0e1;
            "
            :search="search"
            :loading="loading.table"
            :items-per-page="11"
            :headers="headers"
            :items="listVacancies"
            :footer-props="{ itemsPerPageOptions: [11] }"
            class="elevation-0 vacancies__table"
          >
            <template v-slot:no-results>
              <v-alert
                text
                dense
                type="info"
                class="mt-3 forms__alert-completed"
              >
                <b>No se encontraron resultados.</b>
              </v-alert>
            </template>
            <template class="pl-0 pr-0" v-slot:no-data>
              <v-alert
                text
                dense
                type="info"
                class="mt-3 forms__alert-completed"
              >
                <b>No se encontró información.</b>
              </v-alert>
            </template>
            <template v-slot:progress>
              <v-progress-linear
                color="#214484"
                :height="5"
                indeterminate
                stream
              ></v-progress-linear>
            </template>
            <template v-slot:item.title="{ item }">
              <div>
                <p class="mb-0 mt-2 text-1-1-lines">
                  {{ item.title }}
                </p>
              </div>
            </template>
            <template v-slot:item.numero_de_vacantes="{ item }">
              <div>
                <p class="mb-0 mt-2 text-1-1-lines">
                  {{ item.numero_de_vacantes }}
                </p>
              </div>
            </template>
            <template v-slot:item.status_name="{ item }">
              <v-chip
                v-if="item.status_name"
                :color="getColorStatus(item.status_name)"
                dark
                small
              >
                {{ item.status_name }}
              </v-chip>
            </template>
            <template v-slot:item.post_img="{ item }">
              <img width="70" height="50" class="mt-2" :src="item.post_img" />
            </template>
            <template v-slot:item.actions="{ item }">
              <v-hover v-slot="{ hover }">
                <v-btn fab icon small @click="openEdit(item)">
                  <v-icon
                    :style="{
                      color: hover ? '#324CA1' : '#466BE3',
                    }"
                    small
                  >
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </v-hover>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </div>

    <v-dialog width="500">
      <img :src="form.img" alt="imagen de vacante" />
    </v-dialog>

    <Alert
      :open="alert.open"
      :text="alert.txt"
      :title="alert.title"
      :type="alert.type"
      :redirect="alert.redirect"
      @close="alert.open = false"
    >
    </Alert>
    <v-overlay :value="loadingAll">
      <v-progress-circular :size="120" color="#466BE3" indeterminate>
        {{ $translate("general.loading") }}
      </v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import Api from "@/utils/api";
import color from "@/mixins/color";
import { VueEditor } from "vue2-editor";
import Alert from "../../components/Alert.vue";
export default {
  components: {
    VueEditor,
    Alert,
  },
  mixins: [color],
  data() {
    return {
      minDate: new Date().toISOString().substr(0, 10),
      time: null,
      timeIn: null,
      menuTime1: false,
      timeOut: null,
      menuTime2: false,
      loadingAll:false,
      listFilters: [
        {
          id: 0,
          name: "Indefinido",
        },
        {
          id: 1,
          name: "Indefinido manejo y confianza",
        },
        {
          id: 2,
          name: "Obra o Labor",
        },
        {
          id: 3,
          name: "Aprendiz SENA",
        },
        {
          id: 4,
          name: "Bogotá D.C.",
        },
        {
          id: 5,
          name: "Medellín",
        },
        {
          id: 6,
          name: " Cali",
        },
        {
          id: 7,
          name: "Cúcuta",
        },
        {
          id: 8,
          name: "Itagüí",
        },
        {
          id: 9,
          name: "Remoto",
        },
      ],
      filter: "",
      openImg: false,
      headers: [
        {
          text: "Imagen",
          value: "post_img",
          align: "center",
          class: "vacancies__header",
        },
        {
          text: "Título de vacante",
          value: "post_title",
          align: "start",
          class: "vacancies__header",
          sortable: false,
        },
        {
          text: "ID vacante",
          value: "post_id",
          align: "center",
          class: "document__header",
        },
        {
          text: "Estado",
          value: "status_name",
          align: "center",
          class: "vacancies__header",
        },
        {
          text: "Acciones",
          value: "actions",
          align: "center",
          class: "vacancies__header",
          sortable: false,
        },
      ],
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        [{ list: "ordered" }, { list: "bullet" }],
      ],
      listCities: [],
      listCountry:[],
      listContract: [
        "Indefinido",
        "Indefinido manejo y confianza",
        "Obra o Labor",
        "Aprendiz SENA",
      ],
      listMode: ["Remoto", "Presencial", "Hibrido"],
      listStatus: [
        {
          id: "draft",
          name: "Borrador",
        },
        {
          id: "publish",
          name: "Publicado",
        },
        {
          id: "trash",
          name: "Expirado",
        },
      ],
      listVacancies: [],
      alert: {
        txt: "",
        title: "",
        type: "",
        open: false,
        redirect: "",
      },
      loading: {
        table: false,
        add: false,
      },
      search: "",
      form: {
        title: "",
        formacion: "",
        ciudad: "",
        pais:"",
        tipo_contrato: "",
        tipo_modalidad: "",
        mes_ingreso: "",
        horario: "",
        numero_de_vacantes: "",
        objetivo: "<p></p>",
        experiencia: "",
        rango_salarial: "",
        costCentersId: "",
        subCostCentersId: "",
        campaignId: "",
        status: "publish",
        file: null,
        img: true,
        id: null,
      },
      costCenters: [],
      subCostCenters: [],
      campaign: [],
      menu2: false,
      text: {
        btn: "Crear vacante",
        title: "Registrar vacantes",
      },
      id_usuario: 0,
      director: false,
      token: "",
    };
  },
  watch: {
    filter: function (e) {
      if (e) {
        this.getVacanciesFilter(e);
      }
    },
  },
  methods: {
    closeImg() {
      this.form.img = true;
    },
    getColorStatus(status) {
      var color = this.color(status);
      return color;
    },
    openEdit(data) {
      this.form = {
        title: data.post_title,
        formacion: data.formacion,
        ciudad: data.ciudad,
        pais: data.pais,
        tipo_contrato: data.tipo_contrato,
        tipo_modalidad: data.tipo_modalidad,
        mes_ingreso: data.mes_ingreso,
        numero_de_vacantes: data.numero_de_vacantes,
        horario: data.horario,
        objetivo: data.objetivo,
        experiencia: data.experiencia,
        rango_salarial: data.rango_salarial,
        status: this.getStatus(data.status_name),
        costCentersId: data.cc,
        subCostCentersId: data.scc,
        campaignId: data.campania,
        file: null,
        img: data.post_img,
        id: data.post_id,
      };
      this.text.btn = "Editar vacante";
      this.text.title = "Actualizar vacantes";
      this.searchDataCenterCost()
      this.searchDataPais();
    },
    searchDataCenterCost() {
      this.listCostCenter();
      this.listSubCostCenter();
      this.listCampaign();
    },
    searchDataPais(){
      this.listCountrys();
      // this.listCitys();
    },
    getStatus(data) {
      var status = "";
      for (let i = 0; i < this.listStatus.length; i++) {
        if (this.listStatus[i].name == data) {
          status = this.listStatus[i].id;
        }
      }
      return status;
    },
    getVacancies() {
      this.loading.table = true;
      var data = new FormData();
      data.append("id_usuario", this.id_usuario);
      data.append("director", this.director);
      Api.Selection()
        .getVacancies(data)
        .then((resp) => resp.json())
        .then((data) => {
          this.loading.table = false;
          this.listVacancies = data;
          for (let i = 0; i < this.listVacancies.length; i++) {
            var status = "";
            if (this.listVacancies[i].post_status == "draft") {
              status = "Borrador";
            }
            if (this.listVacancies[i].post_status == "publish") {
              status = "Publicado";
            }
            this.listVacancies[i].status_name = status;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading.table = false;
        });
    },
    getVacanciesFilter(id) {
      this.loading.table = true;
      var data = new FormData();
      data.append("id_filter", id);
      data.append("id_usuario", this.id_usuario);
      data.append("director", this.director);
      Api.Selection()
        .getVacanciesFilter(data)
        .then((resp) => resp.json())
        .then((data) => {
          this.loading.table = false;
          this.listVacancies = data;
          for (let i = 0; i < this.listVacancies.length; i++) {
            var status = "";
            if (this.listVacancies[i].post_status == "draft") {
              status = "Borrador";
            }
            if (this.listVacancies[i].post_status == "publish") {
              status = "Publicado";
            }
            this.listVacancies[i].status_name = status;
          }
        })
        .catch((error) => {
          this.listVacancies = [];
        })
        .finally(() => {
          this.loading.table = false;
        });
    },
    saveVacancies() {
      this.loading.add = true;
      if (this.$refs.form.validate()) {
        var data = new FormData();
        data.append("title", this.form.title);
        data.append("formacion", this.form.formacion);
        data.append("ciudad", this.form.ciudad);
        data.append("pais", this.form.pais);
        data.append("tipo_contrato", this.form.tipo_contrato);
        data.append("tipo_modalidad", this.form.tipo_modalidad);
        data.append("mes_ingreso", this.form.mes_ingreso);
        data.append("horario", this.form.horario);
        data.append("numero_de_vacantes", this.form.numero_de_vacantes);
        data.append("objetivo", this.form.objetivo);
        data.append("experiencia", this.form.experiencia);
        data.append("rango_salarial", this.form.rango_salarial);
        data.append("status", this.form.status);
        data.append("post_img", this.form.modifiedFile);
        data.append("cc", this.form.costCentersId);
        data.append("scc", this.form.subCostCentersId);
        data.append("campania", this.form.campaignId);
        data.append("id_usuario", this.id_usuario);
        data.append("director", this.director);
        if (this.form.id != null) {
          data.append("id", this.form.id);
        }

        Api.Selection()
          .createVacancies(data)
          .then((resp) => resp.json())
          .then((data) => {
            this.loading.add = false;
            this.$refs.form.reset();
            this.form.img = true;
            this.form.objetivo = "<p></p>";
            this.text = {
              btn: "Crear vacante",
              title: "Registrar vacantes",
            };
            this.getVacancies();
            this.alert.open = true;
            this.alert.redirect = "";
            if (data.hasOwnProperty("error")) {
              this.alert.type = "error";
              this.alert.title = "¡Oh no!";
              this.alert.txt = data.error;
            } else {
              this.alert.type = "success";
              this.alert.title = "Excelente";
              this.alert.txt = "Se registro correctamente";
            }
          })
          .catch((error) => {
            console.log(error);
            this.alert.open = true;
            this.alert.type = "error";
            this.alert.title = "¡Oh no!";
            this.alert.txt = error;
            this.alert.redirect = "";
          })
          .finally(() => {
            this.loading.add = false;
          });
      }
      else{
      this.loading.add = false;
      }
    },
    containsDirectorOrCoordinador(text) {
      const regex = /director|coordinador/i; // The 'i' indicates that the search should be case-insensitive
      return regex.test(text);
    },
    dataUser() {
      this.id_usuario = this.$store.getters.getUser.id;
      this.director = this.containsDirectorOrCoordinador(
        this.$store.getters.getUser.profile.name
      );
    },
    handleFileUpload() {
      const maxWidth = 550; // Ancho máximo deseado
      const maxHeight = 250; // Altura máxima deseada
      const quality = 0.7; // Calidad de compresión (de 0 a 1)

      const inputFile = this.form.file;

      if (inputFile) {
        this.resizeAndCompressImage(
          inputFile,
          maxWidth,
          maxHeight,
          quality
        ).then((result) => {
          if (result) {
            this.form.modifiedFile = this.createModifiedFile(result, inputFile);
            console.log(this.form.modifiedFile);
          }
        });
      }
    },
    resizeAndCompressImage(inputFile, maxWidth, maxHeight, quality) {
      return new Promise((resolve) => {
        const img = new Image();

        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          let newWidth = img.width;
          let newHeight = img.height;

          if (img.width > maxWidth) {
            newWidth = maxWidth;
            newHeight = (img.height * maxWidth) / img.width;
          }

          if (newHeight > maxHeight) {
            newHeight = maxHeight;
            newWidth = (img.width * maxHeight) / img.height;
          }

          canvas.width = newWidth;
          canvas.height = newHeight;

          ctx.drawImage(img, 0, 0, newWidth, newHeight);

          canvas.toBlob(
            (blob) => {
              resolve(blob);
            },
            "image/jpeg",
            quality
          );
        };

        img.src = URL.createObjectURL(inputFile);
      });
    },
    createModifiedFile(blob, originalFile) {
      const cleanedFileName = originalFile.name.replace(/[^\w.-]/g, ""); 
      const modifiedFile = new File([blob], cleanedFileName, {
        type: originalFile.type,
      });

      return modifiedFile;
    },
    listCountrys() {
      Api.Selection()
        .getCountrySelection(this.token)
        .then((res) => {
          this.listCountry=res.data.data;
          console.log(this.listCountry)
          this.handleCountryChange();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleCountryChange() {
      this.listCitys();
    },
    listCitys(){
      this.listCities=[];
      var data = {
        pais:this.form.pais
      }
      Api.Selection()
        .getCitySelection(this.token,data)
        .then((res) => {
          console.log(res)
          this.listCities=res.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    listCostCenter() {
      Api.Auth()
        .listCostCenter(this.token)
        .then((res) => {
          this.costCenters = res.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    listSubCostCenter() {
      var data = {
        cc: [this.form.costCentersId],
      };
      if (this.form.costCentersId.length === 0) {
        this.subCostCenters = [];
        this.campaign = [];
      } else {
        Api.Auth()
          .listSubCostCenter(this.token, data)
          .then((res) => {
            this.subCostCenters = res.data.data;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    listCampaign() {
      if (this.form.subCostCentersId.length == 0) {
        this.campaign = [];
      } else {
        var data = {
          cc: [this.form.costCentersId],
          scc: [this.form.subCostCentersId],
        };
        Api.Auth()
          .listCampaign(this.token, data)
          .then((res) => {
            this.campaign = res.data.data.filter(
              (item) => item.campaign !== null
            );
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    getToken() {
      var token = localStorage.getItem("token");
      var tokenb64 = localStorage.getItem("tokenB64");

      this.token = tokenb64;
      var div = token.split(".");
      if (div.length >= 2) {
        this.infoToken = JSON.parse(atob(div[1]));
        var nameList = this.infoToken.fullName.split(" ");

        if (nameList.length > 1) {
          if (nameList[0] != "") {
            this.name = nameList[0];
          } else {
            this.name = nameList[1];
          }
        } else {
          this.name = "";
        }
        // console.log(JSON.parse(atob(div[1])));
      } else {
        this.infoToken = {
          document: "",
        };
      }
    },
  },
  mounted() {
    this.getToken();
    if (this.$store.getters.getUser) {
      this.dataUser();
      this.getVacancies();
      this.listCostCenter();
      this.listCountrys();
    }
  },
  watch: {
    "$store.state.user": {
      handler() {
        this.dataUser();
        this.getVacancies();
        this.listCostCenter();
        this.listCountrys();
      },
    },
    listCountry:{
      handler(){
        this.handleCountryChange();
      }
    }
  },
};
</script>
<style lang="less">
@import "./../../assets/css/main.less";

.vacancies__table {
  height: 780px;
  position: relative;
}

.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: rgb(70 107 227 / 57%) !important;
}

.vacancies__table .v-data-table-header {
  font-family: "RobotoRegular";
  background-color: #f2f4ff;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 19px;
  color: #353535 !important;
}

.vacancies__title {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 59px;
  color: #353535;
}

.vacancies__subtitle {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 37px;
  color: #353535;
}

.vacancies__header {
  color: #353535 !important;
}
</style>
